<template>
  <div>
    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loading"
      row-key="id"
    >
      <span slot="item_details" slot-scope="text, record">
        <a-space v-if="isShowItems(record)">
          <a @click="showItemDetails(record)">点击查看</a>
        </a-space>
        <a-space v-else>
          -
        </a-space>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="editHeadUser(record)" v-if="!record.is_end">修改任务</a>
          <a @click="showOperationHistoryModal(record)">操作历史</a>
        </a-space>
      </span>
    </a-table>
    <!-- 详情模态框 -->
    <show-head-user
      v-if="isShowHeadUserModal"
      :visible.sync="isShowHeadUserModal"
      :id="showingId"
      :car-id="carId"
      :user-id="userId"
      :order-type="orderType"
      :service-order-id="serviceOrderId"
      :task-type="taskType"
      :transport-type="transportType"
      @completed="fetchData"
    />
    <!-- 货品明细 -->
    <show-item-details
      v-if="isShowItemDetailsModal"
      :visible.sync="isShowItemDetailsModal"
      :goods-list="goodsList"
    />
    <operation-task-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      :order-id="id"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import { findOrderTasks } from '@/api/order'

export default {
  name: 'TaskList',
  props: {
    id: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  components: {
    ShowHeadUser: () => import('@/views/orders/tenant/operation_modal/TaskHeadUer'),
    ShowItemDetails: () => import('@/views/orders/tenant/GoodsList'),
    OperationTaskHistoryListModal: () => import('@/views/task_operation_histories/Modal')
  },
  data() {
    return {
      data: [],
      isShowHeadUserModal: false,
      showingId: 0,
      taskType: '',
      userId: 0,
      carId: 0,
      carNo: '',
      transportType: '',
      remark: '',
      goodsList: [],
      productList: [],
      showingOperationHistoryId: 0,
      isShowOperationHistoryModal: false,
      isShowConfirmOrderModal: false,
      isShowItemDetailsModal: false,
      loading: true,
      needSortProductTask: [ // 需要对产品进行分类的任务
        'assorting',
        'dispatching',
        'hall_layout'
      ]
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      if (this.orderType === 'service') {
        return this.serviceOrderColumns
      } else {
        return this.subscribeOrderColumns
      }
    },
    serviceOrderColumns() {
      return [
        {
          title: '任务名称',
          dataIndex: 'name',
          fixed: 'left'
        },
        {
          title: '任务状态',
          dataIndex: 'status_display'
        },
        {
          title: '负责人',
          dataIndex: 'head_name'
        },
        {
          title: '货品明细',
          dataIndex: 'item_details',
          scopedSlots: { customRender: 'item_details' }
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '任务创建时间',
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },
    subscribeOrderColumns() {
      return [
        {
          title: '任务名称',
          dataIndex: 'name',
          fixed: 'left'
        },
        {
          title: '任务状态',
          dataIndex: 'status_display'
        },
        {
          title: '负责人',
          dataIndex: 'head_name'
        },
        {
          title: '货品明细',
          dataIndex: 'item_details',
          scopedSlots: { customRender: 'item_details' }
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '任务创建时间',
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    isShowItems(record) {
      return record.record_type_slug === 'service' && this.needSortProductTask.includes(record.task_type_slug) || record.record_type_slug != 'service'
    },
    // 显示操作历史
    showOperationHistoryModal(record) {
      this.showingOperationHistoryId = record.id
      this.isShowOperationHistoryModal = true
    },
    showItemDetails(record) {
      if (this.productList === null) {
        this.productList = []
      }
      this.goodsList = this.productList
      if (this.needSortProductTask.indexOf(record.task_type_slug) !== -1) {
        const goods = []
        if (this.productList.length > 0) {
          let sortNum = 1
          this.productList.forEach(good => {
            if (good.goods_task_slug && good.goods_task_slug.indexOf(record.task_type_slug) !== -1) {
              good.sort_num = sortNum
              goods.push(good)
              sortNum += 1
            }
          })
        }
        this.goodsList = goods
      }
      this.isShowItemDetailsModal = true
    },
    editHeadUser(record) {
      this.showingId = record.id
      this.carId = record.car_id
      this.isShowHeadUserModal = true
      this.userId = record.user_id
      this.taskType = record.task_type_slug
      this.transportType = record.transport_type
      this.remark = record.remark
    },
    fetchData() {
      this.loading = true
      findOrderTasks(this.id, { order_type: this.orderType }).then((res) => {
        if (res.code === 0) {
          this.data = res.data.tasks
          this.productList = res.data.product_list
        }
        this.loading = false
      })
    },
    remove(record) {
      const vm = this
      this.$confirm({
        title: record.user_nature_slug === 'primary' ? '解绑主账号后，子账号也会解除绑定。确定解绑吗？' : '确定解绑吗？',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          findOrderTasks(vm.id, { user_id: record.user_id }).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    }
  }
}
</script>
